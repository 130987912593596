import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import {DATE_FORMAT_DISPLAY_IN_TEXT, TIME_FORMAT_DISPLAY_IN_TEXT} from "../../../constants";
import {sv} from "date-fns/locale";
import Grid from "@mui/material/Unstable_Grid2";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";


const DeleteForm = ({isOpen, onClose, onDelete, booking}) => {
    const {t} = useTranslation();

    const buttonStyle = { margin: "0 auto", display: "block" };

    return (
        <Dialog open={isOpen} >
            <DialogTitle>{t("booking-page.delete-form.title")}</DialogTitle>
            <DialogContent sx={{minWidth: "250px"}}>
                <Grid container>
                    <Grid mr={1}>
                        <AccessTimeIcon />
                    </Grid>
                    <Grid>
                        <Typography variant="body1" component="span">
                            {booking && format(booking?.startTime, TIME_FORMAT_DISPLAY_IN_TEXT, {locale: sv})}
                            -
                            {booking && format(booking?.endTime, TIME_FORMAT_DISPLAY_IN_TEXT, {locale: sv})}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid mr={1}>
                        <CalendarTodayIcon />
                    </Grid>
                    <Grid>
                        <Typography variant="body1">
                            {booking && format(booking?.startTime, DATE_FORMAT_DISPLAY_IN_TEXT, {locale: sv})}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid mr={1}>
                        <MeetingRoomIcon />
                    </Grid>
                    <Grid>
                        <Typography variant="body1">
                            {booking && booking.roomName}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container sx={{width: "100%"}}>
                    <Grid xs={6}>
                        <Button
                            color="error"
                            variant="outlined"
                            onClick={() => onDelete(booking?.id)}
                            sx={buttonStyle}
                        >
                            {t("booking-page.delete-form.delete-button")}
                        </Button>
                    </Grid>
                    <Grid xs={6}>
                        <Button
                            variant="contained"
                            onClick={onClose}
                            sx={buttonStyle}
                        >
                            {t("booking-page.delete-form.cancel-button")}
                        </Button>
                    </Grid>
                </Grid>



            </DialogActions>
        </Dialog>
    )
}

export default DeleteForm;
