import React, { useState } from 'react';
import {
    TextField,
    MenuItem,
    Button,
    Select,
    InputLabel,
    FormControl,
    Container,
    Box,
    Skeleton,
    Typography
} from '@mui/material';
import {useTranslation} from "react-i18next";

const UserForm = ({ isLoading, groups, onSubmit, user, error }) => {
    const {t} = useTranslation();
    const [name, setName] = useState(user?.name || '');
    const [email, setEmail] = useState(user?.email || '');
    const [role, setRole] = useState(user?.role || 'user'); // Default role
    const [groupId, setGroupId] = useState(user?.groupId || '');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ id: user?.id || null, name, email, role, groupId});
    };


    if (isLoading) {
        return (
            <Container maxWidth="sm">
                <Box sx={{ mt: 3 }}>
                    <Skeleton variant="rectangular" width="100%" height={56} sx={{ mb: 2 }} />
                    <Skeleton variant="rectangular" width="100%" height={56} sx={{ mb: 2 }} />
                    <Skeleton variant="rectangular" width="100%" height={56} sx={{ mb: 2 }} />
                    <Skeleton variant="rectangular" width="100%" height={56} sx={{ mb: 2 }} />
                    <Skeleton variant="rectangular" width="100%" height={36} />
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm">
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
                <TextField
                    fullWidth
                    label={t("admin-page.user-form.name-label")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                    required
                />
                <TextField
                    fullWidth
                    label={t("admin-page.user-form.email-label")}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    required
                />
                <FormControl fullWidth margin="normal" required>
                    <InputLabel id="role-label">Role</InputLabel>
                    <Select
                        labelId="role-label"
                        value={role}
                        label={t("admin-page.user-form.role-label")}
                        onChange={(e) => setRole(e.target.value)}
                    >
                        <MenuItem value="user">User</MenuItem>
                        <MenuItem value="admin">Admin</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal" required>
                    <InputLabel id="group-label">Group</InputLabel>
                    <Select
                        labelId="group-label"
                        value={groupId}
                        label={t("admin-page.user-form.group-label")}
                        onChange={(e) => setGroupId(e.target.value)}
                    >
                        {groups.map((group, index) => (
                            <MenuItem key={index} value={group.id}>
                                {group.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Typography variant="body1" color="error">{error}</Typography>
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
                    {user ? t("admin-page.user-form.update-button") : t("admin-page.user-form.create-button")}
                </Button>
            </Box>
        </Container>
    );
};

export default UserForm;
