import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

import Grid from "@mui/material/Unstable_Grid2";


const UserDeleteForm = ({isOpen, onClose, onDelete, user}) => {
    const {t} = useTranslation();

    const buttonStyle = { margin: "0 auto", display: "block" };

    return (
        <Dialog open={isOpen} >
            <DialogTitle>{t("admin-page.delete-user-form.title")}</DialogTitle>
            <DialogContent sx={{minWidth: "250px"}}>
                <Typography variant="body1">
                    {user?.name}
                </Typography>
                <Typography variant="body1">
                    {user?.email}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Grid container sx={{width: "100%"}}>
                    <Grid xs={6}>
                        <Button
                            color="error"
                            variant="outlined"
                            onClick={() => onDelete(user?.id)}
                            sx={buttonStyle}
                        >
                            {t("admin-page.delete-user-form.delete-button")}
                        </Button>
                    </Grid>
                    <Grid xs={6}>
                        <Button
                            variant="contained"
                            onClick={onClose}
                            sx={buttonStyle}
                        >
                            {t("admin-page.delete-user-form.cancel-button")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default UserDeleteForm;
