import {
    IconButton,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import React from "react";
import {useTranslation} from "react-i18next";


const UserTable = ({ isLoading, users, handleEdit, handleDelete }) => {
    const {t} = useTranslation();
    if (isLoading) {
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Skeleton variant="text" /></TableCell>
                            <TableCell><Skeleton variant="text" /></TableCell>
                            <TableCell><Skeleton variant="text" /></TableCell>
                            <TableCell align="right"><Skeleton variant="text" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...Array(3)].map((_, index) => (
                            <TableRow key={index}>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell align="right">
                                    <Skeleton variant="circular" width={40} height={40} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    } else {
        users.sort((a, b) => a.name.localeCompare(b.name));
    }
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("admin-page.user-table.name-column-title")}</TableCell>
                        <TableCell>{t("admin-page.user-table.email-column-title")}</TableCell>
                        <TableCell>{t("admin-page.user-table.group-column-title")}</TableCell>
                        <TableCell>{t("admin-page.user-table.role-column-title")}</TableCell>
                        <TableCell align="right">{t("admin-page.user-table.action-column-title")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user, index) => (
                        <TableRow key={index}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.groupName}</TableCell>
                            <TableCell>{user.role}</TableCell>
                            <TableCell align="right">
                                <IconButton
                                    color="primary"
                                    onClick={() => handleEdit(user.id)}
                                >
                                    <Edit />
                                </IconButton>
                                <IconButton
                                    color="secondary"
                                    onClick={() => handleDelete(user.id)}
                                >
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default UserTable;
