import {FormControl, InputLabel, MenuItem, Select, Skeleton} from "@mui/material";
import {useTranslation} from "react-i18next";


const RoomPicker = ({ isPending, rooms, selectedRoom, onSelectedRoomChange, ...props }) => {

    const { t } = useTranslation();

    if (isPending || !selectedRoom ) return <Skeleton variant="rounded" width={200} height={50} />;

    return (
            <FormControl {...props}>
                <InputLabel id="room-select-label">{t("room-picker.label")}</InputLabel>
                <Select
                    labelId="room-select-label"
                    id="room-select"
                    value={selectedRoom.id}
                    label={t("room-picker.label")}
                    onChange={(event) => onSelectedRoomChange(event.target.value)}
                >
                    {rooms.map(room => (
                        <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
    )
}

export default RoomPicker;
