import {Box, Typography} from "@mui/material";
import {format} from "date-fns";


const Booking = ({booking, openBookingInformation}) => {
    const bookingTitle = booking.groupName ? `${booking.bookerName}, ${booking.groupName}` : `${booking.bookerName}`;
    const bookingLengthHours = (booking?.endTime - booking?.startTime) / 1000 / 60 / 60;
    const isShortBooking = bookingLengthHours < 0.75;
    const isLongBooking = bookingLengthHours >= 1.5;
    let bookingTextStyle = {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    }
    if (isLongBooking) {
        bookingTextStyle = {}
    }
    if (isShortBooking) {
        bookingTextStyle = {
            display: "none",
        }
    }
    return (
        <Box
            key={booking.id}
            sx={(theme) => ({
                position: "absolute",
                top: `${(booking?.startTime.getHours() + booking?.startTime.getMinutes() / 60) * 50}px`,
                left: 0,
                width: "100%",
                height: `${bookingLengthHours * 50}px`,
                backgroundColor: theme.palette.secondary.main,
                opacity: 0.5,
                cursor: "pointer",
            })}
            onClick={() => openBookingInformation(booking)}
        >
            <Typography
                variant="body1"
                p={1}
                color={(theme) => { return theme.palette.info.contrastText }}
                sx={bookingTextStyle}
            >
                {format(booking.startTime, "HH:mm")} - {format(booking.endTime, "HH:mm")}: {bookingTitle}
            </Typography>
        </Box>
    )
}

export default Booking;
