import React from "react";
import AppHeader from "../../components/app-header";
import Grid from "@mui/material/Unstable_Grid2";
import {useState} from "react";
import useAuth from "../../hooks/use-auth";
import {createBooking, deleteBooking, getBookings, getRooms} from "../../services/api";
import {useQuery} from "@tanstack/react-query";
import RoomPicker from "./components/room-picker";
import TimeTable from "./components/time-table";
import RoomServiceDatePicker from "./components/room-service-date-picker";
import BookForm from "./components/book-form";
import {Container} from "@mui/material";
import DeleteForm from "./components/delete-form";

const BOOKING_CONFLICT_ERROR = "Failed to complete booking due to a conflict with another booking.";
const BOOKING_DEFAULT_ERROR = "Failed to complete booking. Please try again later.";

const BookingPage = () => {
    const { token, user } = useAuth();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [selectedStartTime, setSelectedStartTime] = useState(null);
    const [selectedEndTime, setSelectedEndTime] = useState(null);
    const [isBookerOpen, setIsBookerOpen] = useState(false);
    const [bookingErrorMessage, setBookingErrorMessage] = useState(null);
    const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);

    const { isPending: isRoomsPending, data: rooms } = useQuery({
        queryKey: ['rooms'],
        queryFn: async () => {
            const response = await getRooms(token)
            const rooms = response.data.map(room => ({
                id: room.room_id,
                name: room.name,

            }))
            if (!selectedRoom && rooms.length > 0) {
                setSelectedRoom(rooms[0]);
            }
            return rooms;
        },
        enabled: !!token,
    });

    const { isPending: isBookingsPending, data: bookings, refetch: refetchBookings } = useQuery({
        queryKey: ['bookings', selectedDate],
        queryFn: async () => {
            const startTime = new Date(selectedDate);
            startTime.setHours(0);
            startTime.setMinutes(0);
            const endTime = new Date(selectedDate);
            endTime.setHours(23);
            endTime.setMinutes(59);
            const response=  await getBookings(token, startTime, endTime);
            return response.data.map(booking => ({
                id: booking.booking_id,
                startTime: new Date(booking.start_time),
                endTime: new Date(booking.end_time),
                bookerName: booking.booker_name,
                createdAt: booking.created_at,
                roomId: booking.room_id,
                description: booking.description,
                bookerId: booking.booker_id,
                groupName: booking.group_name,
                roomName: booking.room_name
            }));
        },
        enabled: !!token && !!selectedDate && !!rooms && rooms.length > 0,
    });

    const startBooking = (timeString) => {
        // Expects a HH:MM timeString
        const [hours, minutes] = timeString.split(':').map(Number);
        const startTime = new Date(selectedDate);
        const endTime = new Date(selectedDate);

        startTime.setHours(hours);
        startTime.setMinutes(minutes);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);
        endTime.setHours(hours + 1);
        endTime.setMinutes(minutes);
        endTime.setSeconds(0);
        endTime.setMilliseconds(0);
        setSelectedStartTime(startTime);
        setSelectedEndTime(endTime);
        setIsBookerOpen(true);
    }

    const onBookerClose = () => {
        setIsBookerOpen(false);
        setSelectedStartTime(null);
        setSelectedEndTime(null);
        setBookingErrorMessage(null);
    }

    const onSelectedRoomChange = (roomId) => {
        const newRoom = rooms.find(room => room.id === roomId);
        setSelectedRoom(newRoom);
    }

    const bookRoom = async (room, startTime, endTime) => {
        try {
            await createBooking(token, room.id, startTime, endTime)
            setIsBookerOpen(false);
            await refetchBookings()
        } catch (error) {
            if (error.response?.status === 409) {
                setBookingErrorMessage(BOOKING_CONFLICT_ERROR);
            } else {
                setBookingErrorMessage(BOOKING_DEFAULT_ERROR);
            }
        }
    }

    const filterBookings = (bookings, selectedDate, selectedRoom) => {
        return bookings?.filter(booking => {
            const isSameDay = booking.startTime.getDate() === selectedDate.getDate();
            const isSameRoom = booking.roomId === selectedRoom.id;
            return isSameDay && isSameRoom;
        });
    }

    const onBookingDelete = async (bookingId) => {
        try {
            await deleteBooking(token, bookingId);
            setIsDeleteFormOpen(false);
            await refetchBookings();
        } catch (error) {
            console.error(error);
        }
    }

    const openDeleteForm = (booking) => {
        setSelectedBooking(booking);
        setIsDeleteFormOpen(true);
    }

    const closeDeleteForm = () => {
        setIsDeleteFormOpen(false);
        setSelectedBooking(null);
    }

    return (
        <>
            <AppHeader/>
            <Container>
                <Grid container mt={5}>
                    <Grid xs={12} display="flex" justifyContent="center">
                        <RoomServiceDatePicker
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            isPending={false}
                            mb={2}
                        />
                    </Grid>
                    <Grid xs={12} mt={2} p={1}>
                        <RoomPicker
                            isPending={isRoomsPending}
                            rooms={rooms}
                            selectedRoom={selectedRoom}
                            onSelectedRoomChange={onSelectedRoomChange}
                        />
                    </Grid>
                    <Grid xs={12}>
                    <TimeTable
                        isPending={isRoomsPending}
                        startBooking={startBooking}
                        bookings={filterBookings(bookings, selectedDate, selectedRoom)}
                        onBookingDelete={openDeleteForm}
                        user={user}
                    />
                    </Grid>
                    <BookForm
                        isOpen={isBookerOpen}
                        isRoomsPending={isBookingsPending}
                        selectedRoom={selectedRoom}
                        selectedDate={selectedDate}
                        selectedStartTime={selectedStartTime}
                        onSelectedStartTimeChange={setSelectedStartTime}
                        selectedEndTime={selectedEndTime}
                        onSelectedEndTimeChange={setSelectedEndTime}
                        onSubmit={bookRoom}
                        onClose={onBookerClose}
                        errorMessage={bookingErrorMessage}
                    />
                    <DeleteForm
                        isOpen={isDeleteFormOpen}
                        onClose={closeDeleteForm}
                        onDelete={onBookingDelete}
                        booking={selectedBooking}
                        rooms={rooms}
                    />
                </Grid>
            </Container>
        </>
    );
}

export default BookingPage;
