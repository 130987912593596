import {Box, Button, IconButton, Skeleton} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import {sv} from "date-fns/locale";


const RoomServiceDatePicker = ({selectedDate, setSelectedDate, isPending}) => {

    const { t } = useTranslation();

    const previousDate = () => {
        const newDate = new Date(selectedDate)
        newDate.setDate(selectedDate.getDate() - 1)
        setSelectedDate(newDate);
    }

    const nextDate = () => {
        const newDate = new Date(selectedDate)
        newDate.setDate(selectedDate.getDate() + 1)
        setSelectedDate(newDate);
    }

    if (isPending) return <Skeleton variant="rounded" width="70%" height={50} />;

    const arrowButtonStyle = {marginTop: "10px"}

    return (
        <Grid container>
            <Grid>
            <IconButton aria-label="previous-date" onClick={previousDate} sx={arrowButtonStyle}>
                <ArrowBackIosNewIcon />
            </IconButton>
            </Grid>
            <Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
                <DatePicker
                    label={t("date-picker.label")}
                    value={selectedDate}
                    onChange={(newDate) => setSelectedDate(newDate)}
                />
            </LocalizationProvider>
                <Box mt={1}>
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{float: "right"}}
                        onClick={() => setSelectedDate(new Date())}
                    >
                        {t("date-picker.today")}
                    </Button>
                </Box>
            </Grid>
            <Grid>
            <IconButton aria-label="next-date" onClick={nextDate} sx={arrowButtonStyle}>
                <ArrowForwardIosIcon/>
            </IconButton>
            </Grid>
        </Grid>
    );
}

export default RoomServiceDatePicker;
