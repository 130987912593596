import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {format} from "date-fns";
import {useTranslation} from "react-i18next";
import {ADMIN_ROLE, DATE_FORMAT_DISPLAY_IN_TEXT, TIME_FORMAT_DISPLAY_IN_TEXT} from "../../../constants";
import {sv} from "date-fns/locale";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import GroupsIcon from '@mui/icons-material/Groups';
import Grid from "@mui/material/Unstable_Grid2";

const BookingInformationDialog = ({ open, onClose, booking, onBookingDelete, user }) => {
    const {t} = useTranslation();
    console.log(booking);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                {t("booking-page.information-dialog.title")}
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid mr={1}>
                        <AccessTimeIcon />
                    </Grid>
                    <Grid>
                        <Typography variant="body1" component="span">
                            {booking && format(booking?.startTime, TIME_FORMAT_DISPLAY_IN_TEXT, {locale: sv})}
                            -
                            {booking && format(booking?.endTime, TIME_FORMAT_DISPLAY_IN_TEXT, {locale: sv})}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid mr={1}>
                        <CalendarTodayIcon />
                    </Grid>
                    <Grid>
                        <Typography variant="body1">
                            {booking && format(booking?.startTime, DATE_FORMAT_DISPLAY_IN_TEXT, {locale: sv})}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid mr={1}>
                        <MeetingRoomIcon />
                    </Grid>
                    <Grid>
                        <Typography variant="body1">
                            {booking && booking.roomName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid mr={1}>
                        <PersonIcon />
                    </Grid>
                    <Grid>
                        <Typography variant="body1">
                            {booking && booking.bookerName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid mr={1}>
                        <GroupsIcon />
                    </Grid>
                    <Grid>
                        <Typography variant="body1">
                            {booking && booking.groupName}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {
                    (user.role === ADMIN_ROLE || user.id === booking?.bookerId) ?
                        <Button
                            onClick={
                                () => {
                                    onClose();
                                    onBookingDelete(booking)
                                }
                            }
                            color="error"
                        >
                            {t("booking-page.information-dialog.delete-button")}
                        </Button>
                        : null
                }
                <Button onClick={onClose} color="primary">
                    {t("booking-page.information-dialog.close-button")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default BookingInformationDialog;
