import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import svTranslations from "./locales/sv-translations";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            sv: {
                translation: svTranslations
            }
        },
        lng: "sv",
        fallbackLng: "sv",

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
