import {Box, Button, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";


const EmailForm = ({ handleSubmit, errorText }) => {
    const { t } = useTranslation();
    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("email-form.email-label")}
                name="email"
                autoComplete="email"
                autoFocus
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
            >
                {t("email-form.submit-button")}
            </Button>
            {errorText ? <Typography mt={2} color="error">{errorText}</Typography> : null}
        </Box>
    )
}

export default EmailForm;
