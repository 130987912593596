import {createTheme} from "@mui/material";

export const defaultTheme = createTheme({
	palette: {
		primary: {
			main: '#dec560',
			light: '#f5e39a',
			dark: '#b39b49',
			contrastText: '#ffffff',
		},
		secondary: {
			main: '#4b6587',
			light: '#7589a9',
			dark: '#31445b',
			contrastText: '#ffffff',
		},
		error: {
			main: '#d32f2f',
			light: '#ef5350',
			dark: '#c62828',
			contrastText: '#ffffff',
		},
		warning: {
			main: '#f57c00',
			light: '#ffb74d',
			dark: '#e65100',
			contrastText: '#ffffff',
		},
		info: {
			main: '#0288d1',
			light: '#03a9f4',
			dark: '#0277bd',
			contrastText: '#ffffff',
		},
		success: {
			main: '#388e3c',
			light: '#4caf50',
			dark: '#2e7d32',
			contrastText: '#ffffff',
		},
		background: {
			default: '#f5f5f5',
			paper: '#ffffff',
		},
		text: {
			primary: '#333333',
			secondary: '#666666',
			disabled: '#bdbdbd',
			hint: '#9e9e9e',
		},
	},
});
