import {Button, Dialog, DialogTitle, DialogContent, Box, DialogActions} from '@mui/material';
import {createUser, deleteUser, updateUser} from "../../../services/api";
import useAuth from "../../../hooks/use-auth";
import UserTable from "../components/user-table";
import UserForm from "../components/user-form";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import UserDeleteForm from "../components/user-delete-form";

const UserTab = ({isShown, isUsersPending, users, refetchUsers, isGroupsPending, groups}) => {
    const {t} = useTranslation();
    const {token} = useAuth();
    const [isUserFormOpen, setIsUserFormOpen] = useState(false);
    const [userBeingEdited, setUserBeingEdited] = useState(null);
    const [userError, setUserError] = useState("");
    const [isUserDeleteFormOpen, setIsUserDeleteFormOpen] = useState(false);

    const handleDelete = (id) => {
        setUserBeingEdited(users.find(user => user.id === id));
        setIsUserDeleteFormOpen(true);
    };

    const handleEdit = (id) => {
        setUserBeingEdited(users.find(user => user.id === id));
        setIsUserFormOpen(true);
    };

    const handleAddUser = () => {
        setUserBeingEdited(null);
        setIsUserFormOpen(true);
    };

    const handleUserFormSubmit = async (data) => {
        try {
            if (data.id) {
                await updateUser(token, data.id, data.name, data.email, data.role, data.groupId);
            } else {

                await createUser(token, data.name, data.email, data.role, data.groupId);
            }
        } catch (e) {
            console.error(e)
            setUserError(t("admin-page.user-form.error"));
            return;
        }
        setIsUserFormOpen(false);
        await refetchUsers();
    }

    const handleUserDeleteSubmit = async (id) => {
        try {
            await deleteUser(token, id);
        } catch (e) {
            console.error(e)
            setUserError(t("admin-page.delete-user-form.error"))
            return;
        }
        setIsUserDeleteFormOpen(false);
        await refetchUsers();
    }

    const handleCloseUserForm = () => {
        setIsUserFormOpen(false);
        setUserBeingEdited(null);
    }

    const handleCloseUserDeleteForm = () => {
        setIsUserDeleteFormOpen(false);
        setUserBeingEdited(null);
    }

    if (!isShown) return null;

    return (
        <div
            hidden={!isShown}
        >
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddUser}
                style={{ marginBottom: '20px', marginTop: '20px' }}
            >
                {t("admin-page.add-user-button")}
            </Button>
            <UserTable
                isLoading={isUsersPending}
                users={users || []}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
            <Dialog open={isUserFormOpen} onClose={handleCloseUserForm} maxWidth="sm" fullWidth>
                <DialogTitle>{userBeingEdited ? t("admin-page.user-form.update-title") : t("admin-page.user-form.create-title")}</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <UserForm
                            error={userError}
                            isLoading={isGroupsPending}
                            user={userBeingEdited}
                            groups={groups}
                            onSubmit={handleUserFormSubmit}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUserForm} color="secondary">
                        {t("admin-page.user-form.cancel-button")}
                    </Button>
                </DialogActions>
            </Dialog>
            <UserDeleteForm
                user={userBeingEdited}
                onClose={handleCloseUserDeleteForm}
                onDelete={handleUserDeleteSubmit}
                isOpen={isUserDeleteFormOpen}
            />
        </div>
    );
}

export default UserTab;
