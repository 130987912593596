import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import Booking from "./booking";
import {useState} from "react";
import BookingInformationDialog from "./booking-information-dialog";


const TimeTable = ({ isPending, startBooking, bookings, onBookingDelete, user }) => {

    const [isBookingInformationOpen, setIsBookingInformationOpen] = useState(false);
    const [bookingInformation, setBookingInformation] = useState(null);
    const hours = Array.from({length: 24}, (_, i) => {
        if (i < 10) {
            return `0${i}:00`
        } else {
            return `${i}:00`
        }
    });

    const onClick = (event) => {
        startBooking(event.target.parentElement.firstChild.innerText);
    }

    const openBookingInformation = (booking) => {
        setIsBookingInformationOpen(true);
        setBookingInformation(booking);
    }

    if (isPending) return <Skeleton variant="rectangle" width="100%" height="100vh"/>;

    return (
        <>
            <div style={{position: "relative"}}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {hours.map(hour => (
                                <TableRow key={hour}>
                                    <TableCell sx={{paddingTop: 0, width: "50px"}}>{hour}</TableCell>
                                    <TableCell
                                        sx={{height: "50px", '&:hover': {cursor: 'pointer'}}}
                                        onClick={onClick}
                                    >
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{
                    position: "absolute",
                    zIndex: 10,
                    top: 0,
                    left: "70px",
                    width: "calc(100% - 70px)",
                }}>
                    {bookings?.map((booking, idx) => {
                        return <Booking
                            key={idx}
                            booking={booking}

                            user={user}
                            openBookingInformation={openBookingInformation}
                        />
                    })}
                </div>
            </div>
            <BookingInformationDialog
                open={isBookingInformationOpen}
                onClose={() => setIsBookingInformationOpen(false)}
                booking={bookingInformation}
                onBookingDelete={onBookingDelete}
                user={user}
            />
        </>
    )
}

export default TimeTable;
