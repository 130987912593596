import {Container, Box, Tabs, Tab} from '@mui/material';
import AppHeader from "../../components/app-header";
import { getGroups, getUsers } from "../../services/api";
import useAuth from "../../hooks/use-auth";
import {useQuery} from "@tanstack/react-query";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import UserTab from "./components/user-tab";
import GroupTab from "./components/group-tab";

const AdminPage = () => {
    const {t} = useTranslation();
    const {token} = useAuth();
    const {isPending: isUsersPending, data: users, refetch: refetchUsers} = useQuery({
        queryKey: ['users'],
        queryFn: async () => {
            return await getUsers(token);
        },
        enabled: !!token
    })
    const {isPending: isGroupsPending, data: groups, refetch: refetchGroups} = useQuery({
        queryKey: ['groups'],
        queryFn: async () => {
            return await getGroups(token);
        },
        enabled: !!token
    });
    const [openTabIndex, setOpenTabIndex] = useState(0);
    const handleTabChange = (event, newValue) => {
        setOpenTabIndex(newValue);
    }
    return (
        <>
            <AppHeader />
            <Container>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={openTabIndex} onChange={handleTabChange}>
                            <Tab label={t("admin-page.tabs.user-label")} />
                            <Tab label={t("admin-page.tabs.group-label")} />
                        </Tabs>
                    </Box>
                    <UserTab
                        isShown={openTabIndex === 0}
                        index={0}
                        isUsersPending={isUsersPending}
                        users={users}
                        refetchUsers={refetchUsers}
                        isGroupsPending={isGroupsPending}
                        groups={groups}
                    />
                    <GroupTab
                        isShown={openTabIndex === 1}
                        isGroupsPending={isGroupsPending}
                        groups={groups}
                        refetchGroups={refetchGroups}
                    />
                </Box>
            </Container>
        </>
    );
}

export default AdminPage;
